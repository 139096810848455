/* width */
::-webkit-scrollbar {
	width: 10px;
	height: 10px;
	overflow: hidden;
}

/* Track */
::-webkit-scrollbar-track,
::-webkit-scrollbar-corner {
	background-color: rgba(0, 0, 0, 0.6);
}

/* Handle */
::-webkit-scrollbar-thumb {
	background-color: rgba(255, 255, 255, 0.2);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background-color: rgba(255, 255, 255, 0.35);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:active {
	background-color: rgba(255, 255, 255, 0.5);
}
